import React, { Component } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import './Site_name';
//import './Style.css';


class Footer extends Component {
  constructor(){
    super();
    this.state={
      counters:[],
      site:global.site_name,
      
    }
  }
  
  componentDidMount() {
    
    fetch(`https://ksoftpl.com/foodweb-laravel/public/api/home/${this.state.site}`).then((resp)=>{
    resp.json().then((result)=>{
      // console.warn(result)
      this.setState({ counters: result.data.contact});
      
    })
  })
  
}

render() {
  const {counters} = this.state;
  return (
    <div id="contact" className="contain_space pb-3" style={{backgroundColor:'#fff',border: '1px dotted',opacity:'0.8'}}>
    <div className="about-contact-info clearfix para">
    <div className="row" style={{marginTop: 15}}>
    <div className="col-md-3">
    {/*<a class="wget-logo">*/}<img src={`https://ksoftpl.com/foodweb/Admin/assets/logo/${counters.Logo}`} alt="footer logo" className="img center-block footer_logo" style={{height:95,}} />{/*</a>*/}
    </div>
    <div className="col-md-9">
    <div className="row">
    {/* <div className="col-md-12 col-sm-12 col-xs-12  dispaly-flex-footer"> */}
    <div className="col-md-6  col-sm-6 col-xs-12">	
    <div className="row">
    <div className="col-md-1 col-sm-2 col-xs-2">
    <div className="info-icon"><PersonIcon/></div>
    </div>  
    <div className="col-md-10 col-sm-10 col-xs-10">
    <div className="info-content footer_content">
    <p style={{marginTop: 6, borderBottom: '1px dashed #3c763d', width: 265}}>Contact Manager: {counters.c_contact_manger_name}</p>
    </div>
    </div>	
    </div>
    </div>  
    <div className="col-md-6 col-sm-6 col-xs-12">	
    <div className="row">
    <div className="col-md-1 col-sm-2 col-xs-2">
    <div className="info-icon"><EmailIcon /></div>
    </div>  
    <div className="col-md-10 col-sm-10 col-xs-10">
    <div className="info-content footer_content">
    <p style={{marginTop: 6, borderBottom: '1px dashed #3c763d', width: 265}}> {counters.c_email}</p>
    </div>
    </div>	
    </div>
    </div>
    <div className="col-md-6  col-sm-6 col-xs-12">	
    <div className="row">
    <div className="col-md-1 col-sm-2 col-xs-2">
    <div className="info-icon"><PhoneIphoneIcon /></div>
    </div>  
    <div className="col-md-10 col-sm-10 col-xs-10">
    <div className="info-content footer_content">
    <p style={{marginTop: 6, borderBottom: '1px dashed #3c763d', width: 265}}>Mobile Number: {counters.c_mobile_no}</p>
    </div>
    </div>	
    </div>
    </div>
    <div className="col-md-6  col-sm-6 col-xs-12">	
    <div className="row">
    <div className="col-md-1 col-sm-2 col-xs-2">
    <div className="info-icon"><PhoneIcon/></div>
    </div>  
    <div className="col-md-10 col-sm-10 col-xs-10">
    <div className="info-content footer_content">
    <p style={{marginTop: 6, borderBottom: '1px dashed #3c763d', width: 265}}>Desk Number:{counters.c_desk_number}</p>
    </div>
    </div>	
    </div>
    </div>
   
    </div>
    </div>
    </div>
    </div> <hr />
    <div className="text-center mt-1 ">
    <a href="https://ksoftpl.com/" target="_blank" className="para">Designed & Developed by Kanishka Software Pvt. Ltd.</a>
    </div>
    </div>
    
    
    );
  }
}

export default Footer;