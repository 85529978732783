import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Style.css';
import $ from 'jquery';
import './Site_name';


class Menu extends Component {
  constructor(){
    super();
    this.state={
      counters:[],
      site:global.site_name,
    }
  }

  componentDidMount() {
    
    <script>
    $(document).ready(function(){  
        $(".open-offcanvas").click(function(){
          $("body").addClass("offcanvas-on");
        })
        
    });
      $(document).ready(function(){  
        $(".close-offcanvas").click(function(){
          $("body").removeClass("offcanvas-on");
        })
        
    });
      
    </script>

if ($(window).width() > 992) {
  $(window).scroll(function(){  
     if ($(this).scrollTop() > 540) {
        $('#navbar_top').addClass("fixed-top");
        // add padding top to show content behind navbar
        $('body').css('padding-top', $('.navbar').outerHeight() + 'px');        
        $('#navbar_top').css('background-color', 'rgba(255, 255, 255, 0.95)');
      }else{
        $('#navbar_top').removeClass("fixed-top");
         // remove padding top from body
        $('body').css('padding-top', '0');
        $('#navbar_top').css('background-color', 'transparent');
        
      }   
  });
} 
    
    fetch(`https://ksoftpl.com/foodweb-laravel/public/api/home/${this.state.site}`).then((resp)=>{
    resp.json().then((result)=>{
      // console.warn(result)
      this.setState({ counters: result.data.contact});
      
    })
  })
 
}
  render() {
    const {counters} = this.state;
    return (
      <div id="my_menu" >
      
      <div className="header-main shadow rounded mydiv sticy" id="navbar_top">
      <div className="container">
        <div className="row">
      <div className="open-offcanvas">☰</div>
      
      <div className="header-logo col-md-2">
      <a href="/" className="logo logo-static">
      <img src={`https://ksoftpl.com/foodweb/Admin/assets/logo/${counters.Logo}`} alt="foodweb" className="logo-img" />
      </a><a href="/" className="logo logo-fixed">
      <img src={`https://ksoftpl.com/foodweb/Admin/assets/logo/${counters.Logo}`} alt="foodweb" className="logo-img" />
      </a>
      </div>
      <nav id="main-nav-offcanvas" className="main-nav-wrapper col-md-10">
      <div className="close-offcanvas-wrapper"><span className="close-offcanvas">x</span></div>
      <div className="main-nav">
      <ul id="main-nav" className="nav nav-pills main_navbar">
      <li className="nav-item">
      <Link to="/" className="nav-link close-offcanvas">Home</Link>
      </li>
      
      <li className="w3-dropdown-hover">
      <a className="" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Wellness 
      </a>
      <div className="dropdown-menu w3-dropdown-content w3-bar-block w3-border close-offcanvas para" aria-labelledby="navbarDropdown">
      <Link className="dropdown-item close-offcanvas para w3-bar-item w3-button" to="/Healthy_Recipes">Healthy Recipes</Link>
      <Link className="dropdown-item close-offcanvas para w3-bar-item w3-button" to="/Glutenfree">Gluten Free</Link>
      <Link className="dropdown-item close-offcanvas para w3-bar-item w3-button" to="/Allergies">Allergies</Link>
      
      <div className="" />
      
      </div>
      </li>
      <li className="nav-item">
      <Link className="nav-link close-offcanvas" to="/Sustainability">SUSTAINABILITY</Link>
      </li>
      <li className="nav-item w3-dropdown-hover">
      <a className="nav-link " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Catering
      </a>
      <div className="dropdown-menu w3-dropdown-content w3-bar-block w3-border" aria-labelledby="navbarDropdown">
      <Link className="dropdown-item close-offcanvas para w3-bar-item w3-button" to="/Boxed_lunch">Boxed Lunch</Link>
      <Link className="dropdown-item close-offcanvas para w3-bar-item w3-button" to="/Buffet">Buffet</Link>
      
      
      <div className="" />
      
      </div>
      </li>
      <li className="nav-item">
      <Link to="/Pantryservice" className="nav-link  close-offcanvas">Pantry Service</Link>
      </li>
      <li className="nav-item w3-dropdown-hover">
      <a className="" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Updates
      </a>
      <div className="dropdown-menu para w3-dropdown-content w3-bar-block w3-border" aria-labelledby="navbarDropdown">
      <Link className="dropdown-item para  close-offcanvas w3-bar-item w3-button" to="/Special_recipes">Special Recipes</Link>
      <div className="" /> </div>
      </li>
      <li className="nav-item">
      <a className="nav-link  close-offcanvas " id="scrlBotm" >Contact</a>
      </li>
      {/* <li><a href="#contact">Contact</a></li> */}
      {/* <li><Link to="/Glutenfree">Gluten Free</Link></li>
      <li><Link to="/Allergies">Allergies</Link></li>
    <li><Link to="/Pantryservice">Pantry service</Link></li> */}
    
    
    
    
    </ul>
    </div>
    </nav>
    </div>
    </div>
    </div>
    {/* new nav */}
    
    
    </div>
    );
  }
}

export default Menu;