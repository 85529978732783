import React, { Component } from 'react';
import Footer from './Footer';
import './Style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import './Site_name';


class Counter extends Component {


    constructor() {
        super();
        this.state = {
            counters: [],
            counter_image: [],
            site: global.site_name,
        }

    }

    componentDidMount() {

        fetch(`https://ksoftpl.com/foodweb-laravel/public/api/counter_info/${this.state.site}/${this.props.match.params.co_id}`).then((resp) => {
            resp.json().then((result) => {
                //   console.warn(result);
                this.setState({ counters: result.data.counter_info });
                this.setState({ counter_image: result.data.counter_image });

            })
        })
    }


    render(props) {
        // console.log(this.props);
        const co_id = this.props.match.params.co_id;
        const { counters, counter_image } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        const name = { 2: 'Breakfast', 3: 'Lunch', 4: 'EveningSnack', 5: 'Dinner', 6: 'Mid-Night Snack' };
        var settings = {
            arrows: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                    }
                }
            ]
        };



        return (
            <div>
                <div className="overflow-auto box-height bg-white">
                    <div className="container-fluid" id="banner_section" >
                        <div className="row">
                            <div className="col-md-12 col-12 position-relative">

                                <img src={`/assets/images/slider/writing-pad-3193199_1920.jpg`} className="img-fluid position-relative" style={{ maxHeight: '500px', Repeat: 'no-repeat', backgroundSize: 'cover', width: '100%' }} />
                                {/* <img src={`https://ksoftpl.com/foodweb/Admin/assets/vendors_logo/${counter_image}`} className="img-fluid position-absolute counter-logo counter_logo" /> */}
                               

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid contain_space mt-4 pl-5 pr-5">
                        <div className="swin-sc-title text-center">
                            <p className="top-title text-center"><span style={{ fontSize: '12px' }}>Weekly Menu</span></p>

                            {counters.map((counter, index) =>
                                index == 0 && counter.counter_meal_type == 1 ? <h1 className="cat-title2" style={{ fontWeight: 'bold' }}>All DAY</h1> : null
                            )}

                            {
                                counters.length == 0 ? <h1 className="cat-title2" style={{ fontWeight: 'bold' }}>No Data Found</h1> : null
                            }

                        </div>
                    </div>


                    <div className="container-fluid contain_space mb-5 text-center p-0" style={{ overflow: 'hidden' }}>
                        <div className="row">
                            <ul className="nav counterlist col-md-12  col-sm-12">
                                {counters.map(counter =>
                                    counter.counter_meal_type != 1 ?
                                        <li>
                                            <a data-toggle="pill" href={`#${counter.c_id}`}>
                                                <i className="icons swin-icon-pasta pasta_icon"></i>
                                                <p className="box_heading"> {name[counter.counter_meal_type]}</p>
                                            </a>
                                        </li> : null
                                )}
                            </ul>
                        </div>
                        {counters.map((counter, index) => {
                            // console.log(counter);
                            const headings = JSON.parse(counter.counter_heading);
                            const items = JSON.parse(counter.counter_item);
                            const counter_Desc = JSON.parse(counter.counter_Desc)== null ? [null] : JSON.parse(counter.counter_Desc)
                            const counter_KiloCalories = JSON.parse(counter.counter_KiloCalories) == null ? [null] : JSON.parse(counter.counter_KiloCalories);
                            const counter_ServingSize = JSON.parse(counter.counter_ServingSize) == null ? [null] : JSON.parse(counter.counter_ServingSize);
                            const counter_ServedIn = JSON.parse(counter.counter_ServedIn) == null ? [null] : JSON.parse(counter.counter_ServedIn);
                            const counter_ServingPerContainer = JSON.parse(counter.counter_ServingPerContainer) == null ? [null] : JSON.parse(counter.counter_ServingPerContainer);
                            const counter_ServedCup = JSON.parse(counter.counter_ServedCup) == null ? [null] : JSON.parse(counter.counter_ServedCup);
                            const counter_TotalFatValue = JSON.parse(counter.counter_TotalFatValue) == null ? [null] : JSON.parse(counter.counter_TotalFatValue);
                            const counter_TotalFatPercent = JSON.parse(counter.counter_TotalFatPercent) == null ? [null] : JSON.parse(counter.counter_TotalFatPercent);                            
                            const counter_SaturatedFatValue = JSON.parse(counter.counter_SaturatedFatValue) == null ? [null] : JSON.parse(counter.counter_SaturatedFatValue);
                            const counter_SaturatedFatPercent = JSON.parse(counter.counter_SaturatedFatPercent) == null ? [null] : JSON.parse(counter.counter_SaturatedFatPercent);
                            const counter_TransFatValue = JSON.parse(counter.counter_TransFatValue) == null ? [null] : JSON.parse(counter.counter_TransFatValue);
                            const counter_TransFatPercent = JSON.parse(counter.counter_TransFatPercent) == null ? [null] : JSON.parse(counter.counter_TransFatPercent);
                            const counter_CholesterolValue = JSON.parse(counter.counter_CholesterolValue) == null ? [null] : JSON.parse(counter.counter_CholesterolValue);
                            const counter_CholesterolPercent = JSON.parse(counter.counter_CholesterolPercent) == null ? [null] : JSON.parse(counter.counter_CholesterolPercent);
                            const counter_SodiumValue = JSON.parse(counter.counter_SodiumValue) == null ? [null] : JSON.parse(counter.counter_SodiumValue);
                            const counter_SodiumPercent = JSON.parse(counter.counter_SodiumPercent) == null ? [null] : JSON.parse(counter.counter_SodiumPercent);
                            const counter_TotalCarbohydrateValue = JSON.parse(counter.counter_TotalCarbohydrateValue) == null ? [null] : JSON.parse(counter.counter_TotalCarbohydrateValue);
                            const counter_TotalCarbohydratePercent = JSON.parse(counter.counter_TotalCarbohydratePercent) == null ? [null] : JSON.parse(counter.counter_TotalCarbohydratePercent);
                            const counter_DietaryFiberValue = JSON.parse(counter.counter_DietaryFiberValue) == null ? [null] : JSON.parse(counter.counter_DietaryFiberValue);
                            const counter_DietaryFiberPercent = JSON.parse(counter.counter_DietaryFiberPercent) == null ? [null] : JSON.parse(counter.counter_DietaryFiberPercent);
                            const counter_Sugars = JSON.parse(counter.counter_Sugars) == null ? [null] : JSON.parse(counter.counter_VitaminAValue);
                            const counter_AddedSugars = JSON.parse(counter.counter_AddedSugars) == null ? [null] : JSON.parse(counter.counter_VitaminAValue);
                            const counter_AddedSugarsPercent = JSON.parse(counter.counter_AddedSugarsPercent) == null ? [null] : JSON.parse(counter.counter_VitaminAValue);
                            const counter_Protein = JSON.parse(counter.counter_Protein) == null ? [null] : JSON.parse(counter.counter_VitaminAValue);
                            const counter_VitaminAValue = JSON.parse(counter.counter_VitaminAValue) == null ? [null] : JSON.parse(counter.counter_VitaminAValue);
                            const counter_VitaminAPercent = JSON.parse(counter.counter_VitaminAPercent) == null ? [null] : JSON.parse(counter.counter_VitaminAPercent);
                            const counter_CalciumValue = JSON.parse(counter.counter_CalciumValue) == null ? [null] : JSON.parse(counter.counter_CalciumValue);
                            const counter_CalciumPercent = JSON.parse(counter.counter_CalciumPercent) == null ? [null] : JSON.parse(counter.counter_CalciumPercent);
                            const counter_IronValue = JSON.parse(counter.counter_IronValue)== null ? [null] : JSON.parse(counter.counter_IronValue);
                            const counter_IronPercent = JSON.parse(counter.counter_IronPercent)== null ? [null] : JSON.parse(counter.counter_IronPercent);
                            const counter_PotassiumGValue = JSON.parse(counter.counter_PotassiumGValue) == null ? [null] : JSON.parse(counter.counter_PotassiumGValue);
                            const counter_PotassiumPercent = JSON.parse(counter.counter_PotassiumPercent) == null ? [null] : JSON.parse(counter.counter_PotassiumPercent);
                            const counter_DishType = counter.counter_DishType == null ? [null] : JSON.parse(counter.counter_DishType);
                            const counter_Indicator1 = JSON.parse(counter.counter_Indicator1) == null ? [null] : JSON.parse(counter.counter_Indicator1);
                            const counter_Indicator2 = JSON.parse(counter.counter_Indicator2) == null ? [null] : JSON.parse(counter.counter_Indicator2);
                            const counter_Indicator3 = JSON.parse(counter.counter_Indicator3) == null ? [null] : JSON.parse(counter.counter_Indicator3);
                            // console.log(counter.counter_DishType);


                            
                            return (<div data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={3000} className={`${index == 0 ? "tab-content tab-pane fade in active mt-3 fadeInUp  show" : "tab-content tab-pane fade in active mt-5 fadeInUp "}`} id={counter.c_id} style={{ display: 'none' }}>
                                <div className="row m-0  col-container">
                                    {counters.length > 3 ? <Slider {...settings}>
                                        {
                                            headings.map((heading, index) => {
                                               
                                                const counter_PotassiumGVal = counter_PotassiumGValue[index];
                                                const counter_PotassiumPer = counter_PotassiumPercent[index];
                                                const counter_VitaminAVal = counter_VitaminAValue[index];
                                                const counter_CalciumVal = counter_CalciumValue[index];
                                                const counter_VitaminAPer = counter_VitaminAPercent[index];
                                                const counter_CalciumPer = counter_CalciumPercent[index];
                                                const counter_IronVal = counter_IronValue[index];
                                                const counter_IronPer = counter_IronPercent[index];
                                                const menus = items[index];
                                                const counter_Protei = counter_Protein[index];
                                                const counter_AddedSugar = counter_AddedSugars[index];
                                                const counter_AddedSugarsPer = counter_AddedSugarsPercent[index];
                                                const desc = counter_Desc[index];
                                                const counter_KiloCa = counter_KiloCalories[index];
                                                const counter_Servings = counter_ServingSize[index];
                                                const counter_Servedi = counter_ServedIn[index];
                                                const counter_ServingPerCon = counter_ServingPerContainer[index];
                                                const counter_Servedcup = counter_ServedCup[index];
                                                const counter_TotalFatval = counter_TotalFatValue[index];
                                                const counter_TotalFatper = counter_TotalFatPercent[index];                                                
                                                const counter_SaturatedFatval = counter_SaturatedFatValue[index];
                                                const counter_SaturatedFatper = counter_SaturatedFatPercent[index];
                                                const counter_TransFatval = counter_TransFatValue[index];
                                                const counter_TransFatper = counter_TransFatPercent[index];
                                                const counter_CholesterolVal = counter_CholesterolValue[index];
                                                const counter_CholesterolPer = counter_CholesterolPercent[index];
                                                const counter_SodiumVal = counter_SodiumValue[index];
                                                const counter_SodiumPer = counter_SodiumPercent[index];
                                                const counter_TotalCarbohydrateVal = counter_TotalCarbohydrateValue[index];
                                                const counter_TotalCarbohydratePer = counter_TotalCarbohydratePercent[index];
                                                const counter_DietaryFiberVal = counter_DietaryFiberValue[index];
                                                const counter_DietaryFiberPer = counter_DietaryFiberPercent[index];
                                                const counter_Sugar = counter_Sugars[index];
                                                const dish_type = counter_DishType[index];
                                                const indicator1 = counter_Indicator1[index];
                                                const indicator2 = counter_Indicator2[index];
                                                const indicator3 = counter_Indicator3[index];
                                                
                                                
                                                // console.log(dish_type, indicator1, indicator2);
                                                const uniquemenus = [...new Set(menus)];
                                                // const uniquedec = [...new Set(desc)];
                                                // console.log(indicator1);
                                                // console.log(uniquemenus);

                                                return (
                                                <div className="col-md-12">
                                                    <div className="products card shadow card_height_slider" style={{ padding: 15, marginBottom: '30px' }}>
                                                        <div className="row">
                                                            <div className="col-md-12 col-12">
                                                                <h1 className="card_heading card_text_center">{heading} </h1>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="item product-01 pro">
                                                        {
                                                            uniquemenus.map((menu,i) =>
                                                            <div className="row mb-3">
                                                            <div className="col-md-9 col-10">
                                                                <div className="d-flex justify-content-start">
                                                                <div className="d-flex align-items-center">{dish_type == null ? "" : dish_type[i]== 0 ? <img src="/assets/images/icon/vag.png"  width="20px" />: <img src="/assets/images/icon/non-vag-new.png"  width="20px" /> }<h5 className="card_text">{renderHTML(menu)}</h5></div>
                                                                <div className="d-flex align-items-center"><h5 className="card_text weight_text" style={{width:'90px'}}>{counter_KiloCa== null || counter_KiloCa[i] == "" ? <div></div> :<div>({counter_KiloCa[i]} Kcal) / ({counter_Servings[i]}{counter_Servedi[i]})</div>}  </h5>
                                                                <div className="d-flex ml-2">
                                                                    <span>{indicator1 == null || indicator1[i] === "" ? null : <img src={`/assets/images/icon/${indicator1[i]}.jpg`} className="indicator1"  width="20px" />}</span>
                                                                    <span className="ml-2">{indicator2 == null || indicator2[i] === "" ? null : <img src={`/assets/images/icon/${indicator2[i]}.jpg`} className="indicator1"  width="20px" />}</span>
                                                                    <span className="ml-2">{indicator3 == null || indicator3[i] === "" ? null : <img src={`/assets/images/icon/${indicator3[i]}.jpg`} className="indicator1"  width="20px" />}</span>
                                                                    </div></div>
                                                                </div>
                                                                <div>
                                                                    <div >
                                                                        <p className="card_desc">{counter_KiloCa==null ? <div></div>: desc[i]}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 col-2 p-0 m-auto">
                                                            {
                                                                                counter_KiloCa[i] == "" || counter_KiloCa == null ? <div></div>:
                                                                                <Link to={{ pathname: "/Nutrition", dish_name: renderHTML(menu), serving_per_container: counter_ServingPerCon[i], counter_Servedcup: counter_Servedcup[i],
                                                                             counter_Servings: counter_Servings[i], counter_Servedi: counter_Servedi[i], counter_KiloCa: counter_KiloCa[i],
                                                                              counter_TransFatval: counter_TransFatval[i], counter_TransFatper: counter_TransFatper[i],counter_SaturatedFatper:counter_SaturatedFatper[i],
                                                                              counter_SaturatedFatval:counter_SaturatedFatval[i],counter_TotalFatval:counter_TotalFatval[i],counter_TotalFatper:counter_TotalFatper[i],
                                                                              counter_CholesterolVal:counter_CholesterolVal[i],counter_CholesterolPer:counter_CholesterolPer[i],counter_SodiumVal:counter_SodiumVal[i],
                                                                              counter_SodiumPer:counter_SodiumPer[i],counter_TotalCarbohydrateVal:counter_TotalCarbohydrateVal[i],counter_TotalCarbohydratePer:counter_TotalCarbohydratePer[i],
                                                                              counter_DietaryFiberVal:counter_DietaryFiberVal[i],counter_DietaryFiberPer:counter_DietaryFiberPer[i],counter_Sugar:counter_Sugar[i],
                                                                              counter_AddedSugar:counter_AddedSugar[i],counter_AddedSugarsPer:counter_AddedSugarsPer[i],counter_Protei:counter_Protei[i],counter_VitaminAVal:counter_VitaminAVal[i],
                                                                              counter_VitaminAPer:counter_VitaminAPer[i],counter_IronPer:counter_IronPer[i],counter_IronVal:counter_IronVal[i],
                                                                              counter_CalciumPer:counter_CalciumPer[i],counter_CalciumVal:counter_CalciumVal[i],
                                                                              counter_PotassiumGVal:counter_PotassiumGVal[i],counter_PotassiumPer:counter_PotassiumPer[i] }} className="read_more_btn">Read More</Link>
                                                                            }
                                                            </div>

                                                        </div>
                                                            )
                                                        }

                                                        </div>
                                                    </div>
                                                </div>

                                                )
                                            })
                                        }
                                    </Slider> :
                                        headings.map((heading, index) => {
                                            const counter_PotassiumGVal = counter_PotassiumGValue[index];
                                            const counter_PotassiumPer = counter_PotassiumPercent[index];
                                            const counter_VitaminAVal = counter_VitaminAValue[index];
                                            const counter_CalciumVal = counter_CalciumValue[index];
                                            const counter_VitaminAPer = counter_VitaminAPercent[index];
                                            const counter_CalciumPer = counter_CalciumPercent[index];
                                            const counter_IronVal = counter_IronValue[index];
                                            const counter_IronPer = counter_IronPercent[index];
                                            const menus = items[index];
                                            const counter_Protei = counter_Protein[index];
                                            const counter_AddedSugar = counter_AddedSugars[index];
                                            const counter_AddedSugarsPer = counter_AddedSugarsPercent[index];
                                            const desc = counter_Desc[index];
                                            const counter_KiloCa = counter_KiloCalories[index];
                                            const counter_Servings = counter_ServingSize[index];
                                            const counter_Servedi = counter_ServedIn[index];
                                            const counter_ServingPerCon = counter_ServingPerContainer[index];
                                            const counter_Servedcup = counter_ServedCup[index];
                                            const counter_TotalFatval = counter_TotalFatValue[index];
                                            const counter_TotalFatper = counter_TotalFatPercent[index];                                                
                                            const counter_SaturatedFatval = counter_SaturatedFatValue[index];
                                            const counter_SaturatedFatper = counter_SaturatedFatPercent[index];
                                            const counter_TransFatval = counter_TransFatValue[index];
                                            const counter_TransFatper = counter_TransFatPercent[index];
                                            const counter_CholesterolVal = counter_CholesterolValue[index];
                                            const counter_CholesterolPer = counter_CholesterolPercent[index];
                                            const counter_SodiumVal = counter_SodiumValue[index];
                                            const counter_SodiumPer = counter_SodiumPercent[index];
                                            const counter_TotalCarbohydrateVal = counter_TotalCarbohydrateValue[index];
                                            const counter_TotalCarbohydratePer = counter_TotalCarbohydratePercent[index];
                                            const counter_DietaryFiberVal = counter_DietaryFiberValue[index];
                                            const counter_DietaryFiberPer = counter_DietaryFiberPercent[index];
                                            const counter_Sugar = counter_Sugars[index];
                                            const dish_type = counter_DishType[index];
                                            const indicator1 = counter_Indicator1[index];
                                            const indicator2 = counter_Indicator2[index];
                                            const indicator3 = counter_Indicator3[index];
                                            

                                            
                                            const uniquemenus = [...new Set(menus)];


                                            return (
                                                
                                            <div className="card_height col-md-6">
                                                <div className="products card shadow" style={{ padding: 15, marginBottom: '30px' }}>
                                                    <div className="row">
                                                        <div className="col-md-12 col-12 text-center">
                                                            <h1 className="card_heading card_text_center">{heading} </h1>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="item product-01 pro mb-2">
                                                    {
                                                            uniquemenus.map((menu,i) =>
                                                            <div className="row mb-3">
                                                            <div className="col-md-9 col-10">
                                                                <div className="d-flex justify-content-start">
                                                                <div className="d-flex align-items-center">{dish_type == null ? "" : dish_type[i]== 0 ? <img src="/assets/images/icon/vag.png"  width="20px" />: <img src="/assets/images/icon/non-vag-new.png"  width="20px" /> }<h5 className="card_text">{renderHTML(menu)}</h5></div>
                                                                <div className="d-flex align-items-center"><h5 className="card_text weight_text" style={{width:'90px'}}>{counter_KiloCa== null || counter_KiloCa[i] == "" ? <div></div> :<div>({counter_KiloCa[i]} Kcal) / ({counter_Servings[i]}{counter_Servedi[i]})</div>}</h5>
                                                                <div className="d-flex ml-2">
                                                                    {/* {console.log(indicator2[i])} */}
                                                                    <span>{indicator1 == null || indicator1[i] === "" ? null : <img src={`/assets/images/icon/${indicator1[i]}.jpg`} className="indicator1"  width="20px" />}</span>
                                                                    <span className="ml-2">{indicator2 == null || indicator2[i] === "" ? null : <img src={`/assets/images/icon/${indicator2[i]}.jpg`} className="indicator1"  width="20px" />}</span>
                                                                    <span className="ml-2">{indicator3 == null || indicator3[i] === "" ? null : <img src={`/assets/images/icon/${indicator3[i]}.jpg`} className="indicator1"  width="20px" />}</span>
                                                                    </div></div>
                                                                </div>
                                                                <div>
                                                                    <div >
                                                                        <p className="card_desc">{counter_KiloCa == null ? <div></div>: desc[i]}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3 col-2 p-0 m-auto">
                                                            {
                                                                                counter_KiloCa == null || counter_KiloCa[i] == "" ? <div></div>:
                                                                                <Link to={{ pathname: "/Nutrition", dish_name: renderHTML(menu), serving_per_container: counter_ServingPerCon[i], counter_Servedcup: counter_Servedcup[i],
                                                                             counter_Servings: counter_Servings[i], counter_Servedi: counter_Servedi[i], counter_KiloCa: counter_KiloCa[i],
                                                                              counter_TransFatval: counter_TransFatval[i], counter_TransFatper: counter_TransFatper[i],counter_SaturatedFatper:counter_SaturatedFatper[i],
                                                                              counter_SaturatedFatval:counter_SaturatedFatval[i],counter_TotalFatval:counter_TotalFatval[i],counter_TotalFatper:counter_TotalFatper[i],
                                                                              counter_CholesterolVal:counter_CholesterolVal[i],counter_CholesterolPer:counter_CholesterolPer[i],counter_SodiumVal:counter_SodiumVal[i],
                                                                              counter_SodiumPer:counter_SodiumPer[i],counter_TotalCarbohydrateVal:counter_TotalCarbohydrateVal[i],counter_TotalCarbohydratePer:counter_TotalCarbohydratePer[i],
                                                                              counter_DietaryFiberVal:counter_DietaryFiberVal[i],counter_DietaryFiberPer:counter_DietaryFiberPer[i],counter_Sugar:counter_Sugar[i],
                                                                              counter_AddedSugar:counter_AddedSugar[i],counter_AddedSugarsPer:counter_AddedSugarsPer[i],counter_Protei:counter_Protei[i],counter_VitaminAVal:counter_VitaminAVal[i],
                                                                              counter_VitaminAPer:counter_VitaminAPer[i],counter_IronPer:counter_IronPer[i],counter_IronVal:counter_IronVal[i],
                                                                              counter_CalciumPer:counter_CalciumPer[i],counter_CalciumVal:counter_CalciumVal[i],
                                                                              counter_PotassiumGVal:counter_PotassiumGVal[i],counter_PotassiumPer:counter_PotassiumPer[i] }} className="read_more_btn">Read More</Link>
                                                                            }
                                                            </div>

                                                        </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            )
                                        })
                                    }
                                </div>


                            </div>
                            )
                        }

                        )}
                    </div>


                </div>
                <div className="bg-white container-fluid card shadow mb-5">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <h4 className="text-center bold mt-2">Food Indicators</h4>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/0.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains cereals containing gluten</span></div>
                                            <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/1.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains nuts</span></div>
                                            <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/2.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains crustacean or their products</span></div>
                                        </div>

                                        <div className="col-md-3">
                                        <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/3.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains milk or their products</span></div>
                                            <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/4.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains eggs or their products</span></div>
                                            <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/5.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains fish or their products</span></div>
                                        </div>
                                        <div className="col-md-3">                                        
                                        <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/6.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains groundnut, tree nut or their products</span></div>
                                        <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/7.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains Soya or their products</span></div>
                                        <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/8.jpg" alt="" className="indicator_icon" /></span><span className="indi_font">Contains sulphites in concentrations of 10mg/kg or more</span></div>                                           
                                        </div>

                                        <div className="col-md-3">
                                        <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/vag.png" alt="" className="indicator_icon" /></span><span className="indi_font">Veg</span></div>
                                        <div className="d-flex justify-content-start align-items-center mb-2"><span><img src="/assets/images/icon/non-vag-new.png" alt="" className="indicator_icon" /></span><span className="indi_font">Non Veg</span></div>
                                        </div>

                                    </div>
                 </div>

                <Footer />
            </div>
        );
    }
}



export default Counter;